import React, { Component } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
class InfoMarker extends Component {
    state = {
        showInfoWindow: false
    };
    handleMouseOver = e => {
        this.setState({
            showInfoWindow: true
        });
    };
    handleMouseExit = e => {
        this.setState({
            showInfoWindow: false
        });
    };
    render() {
        const { showInfoWindow } = this.state;
        const { note, location } = this.props;
        return (
            <Marker
              position={location}
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseExit}>
                {showInfoWindow && (
                    <InfoWindow>
                        <p>{note}</p>
                    </InfoWindow>
                )}
            </Marker>
        );
    }
}
export default InfoMarker;