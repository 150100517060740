/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import solace from "assets/img/faces/solace.png";
import john from "assets/img/faces/john.png";
import joel from "assets/img/faces/joel.png";
import obie from "assets/img/faces/obie.png";

import solaceBg from "assets/img/backgrounds/solace.png";
import johnBg from "assets/img/backgrounds/john.png";
import joelBg from "assets/img/backgrounds/joel.png";
import obieBg from "assets/img/backgrounds/obie.png";

class About extends Component {
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <div className="card">
                <div className="header">
                  <h4 className="title">Where Everyone Still Travels</h4>
                  <p className="category">
                    Three friends and their journey into the States to find a place to lay one of their father's ashes to rest
                  </p>
                </div>
                <div className="content">
                  <p>Where Everyone Still Travels is a documentary born out of the idea for a road trip. Back before COVID really hit the States, John Walker had the idea to buy a van and get out of the small Florida town that he had lived in for so long. He asked around and O.B. and Joel Milligan answered the call to join along for the trip.</p>
                  <p>Shortly after the plans were laid and an April 21st date was set for departure, COVID-19 hit the States in full force. Everything was shut down (although not as long as it should have been) and the trip had to be postponed.</p>
                  <p>About a month later when things began to reopen and the country started to have fewer cases, the decision was made to start the trip back up. But this time would be different.</p>
                  <p>There had been a lot of changes in the world and the USA. The introduction of social distancing and wearing face masks. BLM protests and the initial riots. Some of the biggest cities in the States turning into ghost towns.</p>
                  <p>It wasn't just enough to see it anymore, it needed to be filmed. The people of the nation would surely want to see an unfiltered view of the country. We decided to do our best to give it to them.</p>
                  <p>Our own personal reasons and goals in tow, we set out from Edgewater Florida on June 4th to travel the country...</p>
                  <iframe title="teaser" width="100%" height="400" src="https://www.youtube.com/embed/heYZgE5xhsA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <UserCard
                bgImage={solaceBg}
                avatar={solace}
                name="The Solace"
                userName=""
                description={
                  <span>
                    The van that steers like a ship
                  </span>
                }
              />
              <UserCard
                bgImage={johnBg}
                avatar={john}
                name="John Walker"
                userName="lionheartywalker"
                description={
                  <span>
                    Actor, Driving, Captain
                  </span>
                }
                socials={
                  <div>
                    <Button simple href="https://www.instagram.com/lionheartywalker/" rel="noopener noreferrer" target="_blank">
                      <i className="fa fa-instagram" />
                    </Button>
                  </div>
                }
              />
              <UserCard
                bgImage={joelBg}
                avatar={joel}
                name="Joel Milligan"
                userName="joel_salt"
                description={
                  <span>
                    26, 6'0", ENFJ-A
                    <br />
                    A Software Engineer on sabbatical to see the USA
                    <br />
                    Filming, Editing, And Navigating
                  </span>
                }
                socials={
                  <div>
                    <Button simple href="https://www.instagram.com/joel_salt/" rel="noopener noreferrer" target="_blank">
                      <i className="fa fa-instagram" />
                    </Button>
                    <Button simple href="https://twitter.com/JoelTravels" rel="noopener noreferrer" target="_blank">
                      <i className="fa fa-twitter" />
                    </Button>
                  </div>
                }
              />
              <UserCard
                bgImage={obieBg}
                avatar={obie}
                name="Obie"
                userName="gumbo4president"
                description={
                  <span>
                    Artist, Navigating, Podcasts
                    <br />
                    "Scoffing away on your ciggies all day"
                  </span>
                }
                socials={
                  <div>
                    <Button simple href="https://www.instagram.com/gumbo4president/" rel="noopener noreferrer" target="_blank">
                      <i className="fa fa-instagram" />
                    </Button>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default About;
