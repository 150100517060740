/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Maps from "views/Maps.jsx";
import About from "views/About.jsx";

const dashboardRoutes = [
  {
    path: "/maps",
    name: "Follow the Trip",
    icon: "pe-7s-graph1",
    component: Maps,
    layout: "/west"
  },
  {
    path: "/about",
    name: "About",
    icon: "pe-7s-notebook",
    component: About,
    layout: "/west"
  },
  {
    social: true,
    path: "https://www.youtube.com/watch?v=heYZgE5xhsA",
    name: "Trailer",
    icon: "pe-7s-video"
  },
  {
    social: true,
    path: "https://www.instagram.com/joel_salt/",
    name: "Instagram",
    icon: "pe-7s-camera"
  },
  {
    social: true,
    path: "https://twitter.com/JoelTravels",
    name: "Twitter",
    icon: "pe-7s-paper-plane"
  },
  // {
  //   social: true,
  //   path: "",
  //   name: "Donate",
  //   icon: "pe-7s-gift"
  // },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "pe-7s-graph",
  //   component: Dashboard,
  //   layout: "/west"
  // },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "pe-7s-user",
  //   component: UserProfile,
  //   layout: "/west"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "pe-7s-note2",
  //   component: TableList,
  //   layout: "/west"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "pe-7s-news-paper",
  //   component: Typography,
  //   layout: "/west"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "pe-7s-science",
  //   component: Icons,
  //   layout: "/west"
  // },
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "pe-7s-rocket",
  //   component: Upgrade,
  //   layout: "/west"
  // }
];

export default dashboardRoutes;
