/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import * as axios from 'axios';
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline
} from "react-google-maps";
import InfoMarker from '../components/Maps/InfoMarker'

const mapDataUrl = 'https://d85ld8py34.execute-api.us-east-2.amazonaws.com/default/WEST-location-read';
const apiKey = 'eWzjcFWGRp6afyf5L7sTFaD27ldGrE2c78XJQ93r';
axios.defaults.headers.common['X-Api-Key'] = apiKey;

const CustomMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={4.7}
      defaultCenter={{ lat: 37.277302, lng: -95.075578 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true
      }}
    >
      <>
      { props.path ? <Polyline path={props.path}/> : null }
      { props.markers 
          ? props.markers.map((marker, index) => {
            return (
              <InfoMarker
                key={index}
                note={marker.note}
                location={marker.location}
                labelAnchor={new window.google.maps.Point(0, 0)}>
              </InfoMarker>
            );
          })
          : null
      }
      </>
    </GoogleMap>
  ))
);

class Maps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markers: null,
      path: null
    };
  }
  componentWillMount() {
    axios.default.get(
      mapDataUrl,
      {
        headers: {
          'X-Api-Key': apiKey,
          'Content-Type': 'application/json'
        }
      }
    )
    .then(response => {
      // TODO: Check response items exist
      return response.data.Items;
    })
    .then(items => {
      let path = [];
      let markers = [];
      items.forEach(item => {
        path.push(item.location);
    
        if (item.note) {
          markers.push(item);
        }
      });
      this.setState({
        path,
        markers
      });
    })
    .catch(error => console.error(error));
  }
  render() {
    return (
      <CustomMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAu6N9uufC8X5ZIrJjbT281OmYFpQbowU8"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `95vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        path={this.state.path}
        markers={this.state.markers}
      />
    );
  }
}

// function Maps({ ...prop }) {
//   return (
//     <CustomMap
//       googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAu6N9uufC8X5ZIrJjbT281OmYFpQbowU8"
//       loadingElement={<div style={{ height: `100%` }} />}
//       containerElement={<div style={{ height: `100vh` }} />}
//       mapElement={<div style={{ height: `100%` }} />}
//     />
//   );
// }

export default Maps;
